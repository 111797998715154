
import { useRoute } from 'vue-router'
import useOtherHomeworks from './useOtherHomeworks'

export default {
  setup: () => {
    const route = useRoute()
    const { courseId } = route.params

    return {
      ...useOtherHomeworks({ courseId })
    }
  }
}
