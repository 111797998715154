
import { computed, ref, Ref, ComputedRef } from 'vue'
import { Page } from 'momai'

type FetchFunction = (pageData: Page, loadEnd: () => void) => void;

/**
 * 加载选项
 */
interface LoadOption {
  /**
   * 数据总条数
   */
  total: Ref<number>;
  /**
   * @type {Page} 分页数据
   */
  pageData?: Page;
  /**
   * 需要在该函数里根据分页参数获取下一页的数据，并追加至加载列表
   *
   * @type {FetchFunction}
   * @memberof LoadOption
   */
  fetch: FetchFunction;
  /**
   * 需要进行滚动加载数组
   */
  list: Ref<any[]>;
}

const useLoadList = ({
  total,
  list,
  fetch,
  pageData = {
    size: 10,
    current: 1
  }
}: LoadOption): {
  reset: () => void;
  loadMore: () => void;
  finished: ComputedRef<boolean>;
  loading: Ref<boolean>;
} => {
  const finished = computed(() => total.value >= 0 && list.value.length >= total.value)
  const loading = ref(false)
  const loadMore = () => {
    if (finished.value || loading.value) {
      return
    }
    loading.value = true
    fetch(pageData, () => {
      loading.value = false
    })
    pageData.current++
  }

  const reset = () => {
    pageData.current = 1
    total.value = -1
    list.value = []
    loadMore()
  }

  loadMore()

  return {
    reset,
    finished,
    loading,
    loadMore
  }
}

export {
  LoadOption,
  FetchFunction,
  useLoadList
}
