import { api } from '@/api/useAxios'
import { FetchFunction, useLoadList } from '@/utils/useLoadList'
import { Homework, ActiveColor } from 'momai'
import { ref } from 'vue'
import { Toast } from 'vant'

interface OtherStudentHomework extends Homework {
  workDzCnt: number;
  workDzColor: ActiveColor;
  workPjCnt: number;
  workPjColor: ActiveColor;
}

export default ({
  courseId
}: {
  courseId: string | string[];
}) => {
  const totalNum = ref(-1)
  const otherStudentHomeworks = ref<OtherStudentHomework[]>([])

  const fetch: FetchFunction = (pageData, loadEnd) => {
    api.post('/homework/getOtherStuPageWorks', {
      courseInfoId: courseId,
      ...pageData
    }).then(({ pageWorks }) => {
      const { total, records } = pageWorks
      totalNum.value = total
      otherStudentHomeworks.value.push(...records)
      loadEnd()
    })
  }

  return {
    ...useLoadList({
      fetch,
      list: otherStudentHomeworks,
      total: totalNum
    }),
    otherStudentHomeworks,
    likeOrUnlike: (hw: OtherStudentHomework) => {
      const { id, workDzColor } = hw
      const isAdd = workDzColor === '#8a8a8a'
      api.post('/mmdianzan/addOrCancelDz', {
        // eslint-disable-next-line @typescript-eslint/camelcase
        ord_id: id,
        dealType: isAdd ? 'add' : 'cancel',
        type: 1
      }).then(() => {
        hw.workDzColor = isAdd ? 'orange' : '#8a8a8a'
        hw.workDzCnt += isAdd ? 1 : -1
        Toast.success(`${isAdd ? '' : '取消'}点赞成功`)
      })
    }
  }
}
